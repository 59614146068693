import React from "react"
import { Link } from "gatsby"
import SEO from "../components/shared/seo"
import Construction from "./../components/shared/construction"

const BlogPage = () => (
  <div className="blog flexed" style={{ flexDirection: "column" }}>
    <SEO title="Blog" />
    <Construction />
    <Link to="/" style={{ margin: "0 auto" }}>
      Go back to the homepage
    </Link>
  </div>
)

export default BlogPage
