import React from 'react'

export default function Construction() {
  return (
    <div>
      <div className="" style={{ marginTop: "10vw" }}>
        <h1 style={{ "fontSize": "25px", color: "white" }} className="center-text">
          This page is under construction.
     </h1>
        <h6 style={{ "fontSize": "3vw" }} className="center-text"> Check back later!</h6>
      </div>
    </div>
  )
}
